import React, { ReactElement, useState, useMemo, useEffect } from 'react';
import {
  Questionnaire as ApiQuestionnaire,
  Submission,
  Error,
  getIncludeSecurityCredentials,
  SubmissionAnswer,
  QuestionConditions,
  getSubmitEndpoint,
} from '../../api';
import Question from '../Question';
import ProgressBar from '../ProgressBar';
import Welcome from '../Welcome';
import SectionDivide from '../SectionDivide';
import Submitted from '../Submitted';
import { FullWidthWrapper } from './styles';

export default function Questionnaire({
  questionnaire,
  isPreview,
  accessToken,
  studentName,
  api,
  onPart2,
  onPart1,
}: {
  questionnaire: ApiQuestionnaire;
  isPreview: boolean;
  accessToken: string;
  studentName: string;
  api: string | null;
  onPart2: () => void;
  onPart1: () => void;
}): ReactElement {
  const [current, updateCurrent] = useState<number>(0);
  const [submissionStatus, setSubmissionStatus] = useState<'loading' | 'success' | 'error' | 'ServerSide' | 'preview'>(
    'loading'
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [reverse, setReverse] = useState<boolean>(false);
  const [answers, updateAnswers] = useState<{ [key: number]: SubmissionAnswer }>({});
  const isSend = 'send' === questionnaire.type;
  const essentialSkillsPos = questionnaire.questions.findIndex((q) => q.responseType.type === 'EssentialSkills');
  const divider = essentialSkillsPos === -1 ? 0 : essentialSkillsPos;
  const divider2Pos = questionnaire.questions.findIndex((q) => q.responseType.type === 'CareerPlanning');
  const divider2 = divider2Pos === -1 ? 0 : divider2Pos;
  //current question here too or maybe make it state
  const totalQuestions = questionnaire.questions.length;

  const slides = useMemo(() => {
    const q = Array.from({ length: totalQuestions }, (_, i) => i + 1);

    if (isSend) {
      return [
        'welcome',
        ...['part1', ...q.slice(0, divider), 'part2', ...q.slice(divider, divider2), 'part3', ...q.slice(divider2)],
        'submit',
      ];
    } else {
      return ['welcome', ...(divider ? ['part1', ...q.slice(0, divider), 'part2', ...q.slice(divider)] : q), 'submit'];
    }
  }, [divider, divider2, totalQuestions, questionnaire]);
  const questionNum = slides[current];
  const currentQuestion: number =
    typeof questionNum === 'number'
      ? questionNum <= divider
        ? questionNum
        : divider2 > 0
        ? questionNum <= divider2
          ? questionNum - divider
          : questionNum - divider2
        : questionNum - divider
      : 0;
  const total =
    Number(questionNum) <= divider
      ? divider
      : divider2 > 0
      ? Number(questionNum) <= divider2
        ? totalQuestions - divider - (totalQuestions - divider2)
        : totalQuestions - divider2
      : totalQuestions - divider;

  useEffect(() => {
    if (Object.values(answers).length > 0 && slides[current] !== 'submit' && !isPreview) {
      const h: EventListener = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
        return true;
      };
      window.addEventListener('beforeunload', h);
      return () => window.removeEventListener('beforeunload', h);
    }
  }, [answers, current, slides, isPreview]);

  useEffect(() => {
    const submit = () => {
      const sendAnswers = (): Promise<Error | Response> => {
        if (!isPreview) {
          const body: Submission = {
            accessToken: accessToken,
            questionnaireId: questionnaire.id,
            answers: questionnaire.questions.map((q) => answers[q.id]).filter((a) => a != null),
          };
          return fetch(getSubmitEndpoint(api), {
            method: 'options',
            body: JSON.stringify(body),
            credentials: getIncludeSecurityCredentials()
          });
        } else {
          return Promise.resolve({ errors: '', status: 418, type: 'Didnt send', title: 'Preview' });
        }
      };
      sendAnswers()
        ?.then((res) => {
          if (res.status === 418) {
            setSubmissionStatus('preview');
          } else if (res.status >= 200 && res.status <= 204) {
            setSubmissionStatus('success');
          } else {
            if (res instanceof Response) {
              res.text().then(text =>
                  console.error('compass-post-bad-response', questionnaire, answers, {
                    type: res.type,
                    status: res.status,
                    statusText: res.statusText,
                    url: res.url,
                    body: text
                  }));
            }
            else {
              console.error('compass-post-error', questionnaire, answers, {
                type: res.type,
                status: res.status,
              });
            }
            setSubmissionStatus('error');
          }
        })
        .catch(() => setSubmissionStatus('ServerSide'));
    };
    if (submitted && Object.values(answers).length > 0) {
      submit();
    }
  }, [answers, questionnaire, submitted, accessToken, isPreview, api]);
  const nextQuestion = (answer: SubmissionAnswer | void) => {
    updateCurrent(current + 1 + skipQuestions(current + 1, answer, false));

    if (answer) {
      updateAnswers({
        ...answers,
        [answer.questionId]: answer,
      });
    }

    setReverse(false);
    if (questionNum === divider) {
      onPart2();
    }
  };

  const previousQuestion = (answer: SubmissionAnswer | void) => {
    setReverse(true);
    updateCurrent(current - 1 + skipQuestions(current - 1, null, true));
    if (answer) {
      updateAnswers({
        ...answers,
        [answer.questionId]: answer,
      });
    }
  };

  function skipQuestions(questionNumber: number, currentAnswer: SubmissionAnswer|void|null, isReverse: boolean): number {
    let numberToSkip = 0;

    while (shouldSkipQuestion(questionNumber + numberToSkip, currentAnswer)) {
      isReverse ? numberToSkip-- : numberToSkip++
    }

    return numberToSkip;
  }

  function shouldSkipQuestion(questionNumber: number, currentAnswer: SubmissionAnswer|void|null): Boolean{

    if (!questionnaire.questions[questionNumber-2]){
      return false;
    }

    let shouldSkip = false;

    questionnaire.questions[questionNumber-2].conditionToShow?.forEach((questionCondition: QuestionConditions) => {
      let andResult = Object.keys(questionCondition).length > 0;

      Object.keys(questionCondition).forEach((key) => {
        const isCurrentAnswer = currentAnswer && currentAnswer.questionId === Number(key);

        if (andResult && isCurrentAnswer && currentAnswer && !questionCondition[key].some(answer => currentAnswer.responseOptionId.includes(answer))){
          andResult = true;
        }
        else if (andResult && !isCurrentAnswer && answers[Number(key)] && !questionCondition[key].some(answer => answers[Number(key)].responseOptionId.includes(answer))){
          andResult = true;
        }
        else {
          andResult = false;
        }
      });

      if (andResult) {
        shouldSkip = true;
      }
    });

    if (shouldSkip){
      delete answers[questionnaire.questions[questionNumber-2].id];
    }

    return shouldSkip;
  }

  if (typeof questionNum === 'number') {
    return (
      <FullWidthWrapper aria-live="polite">
        <Question
          currentQuestion={currentQuestion}
          selected={answers[questionnaire.questions[questionNum - 1]?.id]}
          key={questionnaire.questions[questionNum - 1].id}
          question={questionnaire.questions[questionNum - 1]}
          onNext={nextQuestion}
          onPrevious={previousQuestion}
          onSubmit={() => setSubmitted(true)}
          lastQuestion={questionNum === totalQuestions}
          reverse={reverse}
          isPreview={isPreview}
        >
          <ProgressBar currentQuestion={currentQuestion} totalQuestions={total}></ProgressBar>
        </Question>
      </FullWidthWrapper>
    );
  } else if (questionNum === 'welcome') {
    return <Welcome onClick={() => nextQuestion()} studentName={studentName} />;
  } else if (questionNum === 'part1') {
    return <SectionDivide part={1} isSend={isSend} onNext={() => nextQuestion()} onPrevious={previousQuestion} />;
  } else if (questionNum === 'part2') {
    return (
      <SectionDivide
        part={2}
        isSend={isSend}
        onNext={() => nextQuestion()}
        onPrevious={() => {
          onPart1();
          previousQuestion();
        }}
      />
    );
  } else if (questionNum === 'part3') {
    return (
      <SectionDivide
        part={3}
        isSend={isSend}
        onNext={() => nextQuestion()}
        onPrevious={() => {
          onPart1();
          previousQuestion();
        }}
      />
    );
  } else {
    return <Submitted status={submissionStatus}></Submitted>;
  }
}
