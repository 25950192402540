import { CenteredText, ErrorContainer } from '../styles-components';

export default function Error({
  error,
}: {
  error: 'NoAccessToken' | 'NotFound' | 'InvalidLink' | 'ExpiredLink' | 'Loading' | 'ServerSide' | 'PostError' | 'RateLimit';
}) {
  if ('NotFound' === error) {
    return (
      <ErrorContainer>
        <h2>404 Error</h2>
        <CenteredText>
          It looks like there's something wrong with the link you went to, we can't find the questionnaire you are
          trying to complete. Please check with your teacher to try again.
        </CenteredText>
      </ErrorContainer>
    );
  }
  if ('InvalidLink' === error) {
    return (
      <ErrorContainer>
        <CenteredText>
          This questionnaire has already been completed. Let your teacher know if you think there is an issue and they
          should be able to help.
        </CenteredText>
        <CenteredText>You may now close your browser.</CenteredText>
      </ErrorContainer>
    );
  }
  if ('ExpiredLink' === error) {
    return (
      <ErrorContainer>
        <CenteredText>
        Sorry, your Future Skills Questionnaire link has been invalidated due to a technical issue. Your Careers Leader or
        teacher that generated the link for you has already been notified and will hopefully generate a new link for you soon. Thank you.
        </CenteredText>
      </ErrorContainer>
    );
  }
  if ('ServerSide' === error) {
    return (
      <ErrorContainer>
        <CenteredText>Something is wrong with the questionnaire at the moment. Please try again later.</CenteredText>
      </ErrorContainer>
    );
  } else if ('NoAccessToken' === error) {
    return (
      <ErrorContainer>
        <h2>400 Error</h2>
        <CenteredText>You should have been provided with a link to complete the survey</CenteredText>
      </ErrorContainer>
    );
  } else if ('PostError' === error) {
    return (
      <ErrorContainer>
        <h2>404 Error</h2>
        <CenteredText>Error sending response</CenteredText>
      </ErrorContainer>
    );
  } else if ('RateLimit' === error) {
    return (
      <ErrorContainer>
        <CenteredText>Sorry - we are too busy to handle your request right now - please try again later.</CenteredText>
      </ErrorContainer>
    );
  }
  else {
    return (
      <ErrorContainer>
        <h2>Loading</h2>
      </ErrorContainer>
    );
  }
}
