import React, { useEffect, useState } from 'react';
import 'reset-css';
import { ThemeProvider } from 'styled-components';
import Logo from './cec-logo.svg';
import { GlobalStyle, PreviewBar, Header, SENDTheme, Section1Theme, Section2Theme, Container } from './styles';
import {
  Questionnaire as ApiQuestionnaire,
  getIncludeSecurityCredentials,
  getValidateAccessTokenEndpoint,
} from './api';
import Questionnaire from './components/Questionnaire';
import Error from './components/Error';

function App() {
  const [questionnaire, setQuestionnaire] = useState<ApiQuestionnaire>();
  const [error, setError] = useState<'NoAccessToken' | 'InvalidLink' | 'NotFound' | 'ExpiredLink' | 'ServerSide' | 'Loading' | 'RateLimit'>(
    'Loading'
  );
  const [accessToken, setAccessToken] = useState<string | null>();
  const [api, setApi] = useState<string | null>(null);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [studentName, setStudentName] = useState<string>('');
  const [theme, setTheme] = useState<{ bg: string }>(Section1Theme);

  useEffect(() => {
    const urlPath = new URL(window.location.href);
    const params = urlPath.searchParams;
    const slugs = urlPath.pathname.split('/');

    if (null !== params.get('accessToken')) {
      setAccessToken(params.get('accessToken'));
    } else {
      if (null !== slugs[1]) {
        if ('' !== slugs[1]) {
          setAccessToken(slugs[1]);
        }
      }
    }

    if (null !== params.get('api')) {
      setApi(params.get('api'));
    } else {
      if (2 < slugs.length) {
        if (null !== slugs[2]) {
          if ('' !== slugs[2]) {
            setApi(slugs[2]);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (null === accessToken) {
      setError('NoAccessToken');
    }
  }, [accessToken]);
  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
          const fetched = await fetch(
              getValidateAccessTokenEndpoint(api, accessToken),
              { credentials: getIncludeSecurityCredentials(), method: 'OPTIONS' }
          );

          if (429 === fetched.status) {
            setError('RateLimit')
            return;
          }

          const json = await fetched.json();
          if (404 === fetched.status) {
            switch(String(json.title)) {
              case 'Not Found': {
                setError('NotFound');
                break;
              }
              case 'Token already used': {
                setError('InvalidLink');
                break;
              }
              case 'Token expired': {
                setError('ExpiredLink');
                break;
              }
            }
          }

          setQuestionnaire(json.questionnaire);
          setIsPreview(json.isPreview);
          setStudentName(json.studentName);
        } catch {
          setError('ServerSide');
          if ('production' !== api && process.env.NODE_ENV) {
            setError('NotFound');
          }
        }
      })();
    }
  }, [accessToken, api]);
  useEffect(() => {
    setTheme('send' === questionnaire?.type ? SENDTheme : Section1Theme);
  }, [questionnaire]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={theme} />
      {isPreview ? (
        <PreviewBar>
          <p>This is a preview questionnaire. Your responses will not be saved.</p>
        </PreviewBar>
      ) : null}

      <Header>
        <img src={Logo} alt="cec-logo"></img>
      </Header>
      {/* <Grid> */}
      <Container>
        {questionnaire && accessToken && (
          <Questionnaire
            questionnaire={questionnaire}
            accessToken={accessToken}
            isPreview={isPreview}
            studentName={studentName}
            api={api}
            onPart2={() => {
              'send' !== questionnaire.type && setTheme({ ...Section2Theme });
            }}
            onPart1={() => {
              'send' !== questionnaire.type && setTheme({ ...Section1Theme });
            }}
          />
        )}
        {!questionnaire && <Error error={error}></Error>}
        {/* {!questionnaire && !error && 'loading'} */}
      </Container>
      {/* </Grid> */}
    </ThemeProvider>
  );
}

export default App;
